import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/SiteLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-is-javascript"
    }}>{`What is JavaScript?`}</h1>
    <p>{`In the last chapter we discussed `}<em parentName="p">{`programming`}</em>{` in general, and what a `}<em parentName="p">{`programming language`}</em>{` is.`}</p>
    <blockquote>
      <p parentName="blockquote">{`A programming language is a language that humans can write and computers can make sense of.`}</p>
    </blockquote>
    <p>{`In this chapter, we're going to talk about one specific programming language: JavaScript (or JS for short). You may or may not have ever heard of JavaScript, but I `}<em parentName="p">{`know`}</em>{` you've used a website or application that was built with it. How do I know that? Well, because this site was built with it!`}</p>
    <p>{`We'll look at some details about JS in a bit, but let's first look at some things that can be built with JS.`}</p>
    <h2 {...{
      "id": "what-is-javascript-good-for"
    }}>{`What is JavaScript good for?`}</h2>
    <p>{`JavaScript can be used to build a lot of things nowadays! Here's a list of some examples:`}</p>
    <ul>
      <li parentName="ul">{`Most modern websites use JS to make their web pages interactive. You click a button and something pops up: JS is likely responsible for that. You enter some code into the website and then run it: JS is likely responsible for that.`}</li>
      <li parentName="ul">{`JS can be used to build mobile phone apps - and there are `}<em parentName="li">{`plenty`}</em>{` of mobile apps that `}<em parentName="li">{`are`}</em>{` built with JS!`}</li>
      <li parentName="ul">{`JS can be used to build desktop applications (applications that run on your computer, not through a web browser).`}</li>
      <li parentName="ul">{`JS can be used to build "servers" that do things like process payments, or access data from some sort of database.`}</li>
      <li parentName="ul">{`And more!`}</li>
    </ul>
    <p>{`The possibilities with JS are practically endless! But here's the kicker:`}</p>
    <blockquote>
      <p parentName="blockquote">{`JavaScript is relatively easy to learn and use!`}</p>
    </blockquote>
    <p>{`In this book we'll be learning about programming in general, but we'll use JavaScript as a programming language to explore programming ideas. By the end of this, you will not only have an understanding of programming - but also about JavaScript!`}</p>
    <h2 {...{
      "id": "comments-in-javascript"
    }}>{`Comments in JavaScript`}</h2>
    <p>{`When programming, it is a good idea to document your code so it's easier to understand. To do this, we use code `}<em parentName="p">{`comments`}</em>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`In programming, a `}<strong parentName="p">{`comment`}</strong>{` is a human-readable explanation that is `}<em parentName="p">{`not`}</em>{` executed by the program.`}</p>
    </blockquote>
    <p>{`This is best portrayed with an example. Tinker with the first line in the live snippet below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=wij-comment",
        "live": "true",
        "id": "wij-comment"
      }}>{`// THIS IS A COMMENT! I don't "do" anything.
$log("I do something though.");
`}</code></pre>
    <p>{`In JavaScript, any line that starts with `}<inlineCode parentName="p">{`//`}</inlineCode>{` will be considered a "comment" and not executed by the program. These comments are just for humans! This will help us a lot throughout this book - but is `}<em parentName="p">{`even more useful`}</em>{` when writing code for large applications! It's important to communicate what code does, and why it does what it does, to other programmers (including your future self!).`}</p>
    <p>{`Let's look at one last example that illustrates why this is useful. Read through the code in the live snippet below. Even if you don't understand all of the code, you should at least be able to make sense of what's going on due to the comments.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=wij-sun",
        "live": "true",
        "id": "wij-sun"
      }}>{`// Create a sun looking thing.

// Create a star that is as wide as the stage
// And put it at the center of the stage
$star($stageWidth/2, $stageWidth/2, $stageHeight/2, { fill: "yellow" });

// Create an orange circle at the center of the star
$circle($stageWidth/4, $stageWidth/2, $stageHeight/2, { fill: "orange" });
`}</code></pre>
    <p>{`If the `}<inlineCode parentName="p">{`$star`}</inlineCode>{` and `}<inlineCode parentName="p">{`$circle`}</inlineCode>{` bits look confusing - that's okay! Can you at least tell what's going on based on the comments?`}</p>
    <h2 {...{
      "id": "semicolons"
    }}>{`Semicolons`}</h2>
    <p>{`In a previous live snippet, we saw a line like this `}<inlineCode parentName="p">{`$log("I do something though.");`}</inlineCode>{`. Notice the semicolon `}<inlineCode parentName="p">{`;`}</inlineCode>{` at the end of that? You might be wondering why that's there. In JavaScript we can add a `}<inlineCode parentName="p">{`;`}</inlineCode>{` to declare that we're done with the current "statement" or "instruction". We can make a whole new instruction after that `}<inlineCode parentName="p">{`;`}</inlineCode>{`. Let's see an example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=wij-double-command",
        "live": "true",
        "id": "wij-double-command"
      }}>{`// Two instructions on one line!
$log("Hello world!"); $log(5 + 5);
`}</code></pre>
    <p>{`However, if you just add a new line, it will usually do the same thing - so these `}<inlineCode parentName="p">{`;`}</inlineCode>{` pieces aren't always necessary. For example, we could have just as easily split this into two lines:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=wij-two-lines",
        "live": "true",
        "id": "wij-two-lines"
      }}>{`$log("Hello world!")
$log(5 + 5)
`}</code></pre>
    <p>{`It doesn't `}<em parentName="p">{`hurt`}</em>{` to add the `}<inlineCode parentName="p">{`;`}</inlineCode>{` at end of lines, so you'll be seeing these at the end of lines throughout the code snippets in this book.`}</p>
    <h2 {...{
      "id": "-helpers"
    }}>{`$ Helpers`}</h2>
    <p>{`At this point, you've already seen a handful of commands that start with a `}<inlineCode parentName="p">{`$`}</inlineCode>{`, such as `}<inlineCode parentName="p">{`$log`}</inlineCode>{` or `}<inlineCode parentName="p">{`$circle`}</inlineCode>{`. Anything that you see in this book that starts with a `}<inlineCode parentName="p">{`$`}</inlineCode>{` isn't built into JavaScript - it's something that `}<em parentName="p">{`we`}</em>{` added to this site to make it a bit more fun!`}</p>
    <p>{`These `}<inlineCode parentName="p">{`$`}</inlineCode>{` helpers allow us to make some interesting things right away so we can dive right into programming ideas without worrying about `}<em parentName="p">{`too many`}</em>{` details. To see all of the available helpers, `}<a parentName="p" {...{
        "href": "/helpers"
      }}>{`go check out this page`}</a>{`.`}</p>
    <h2 {...{
      "id": "pixels"
    }}>{`Pixels`}</h2>
    <p>{`Throughout this book you'll also see the term "pixel" ("px" for short). Pixels aren't a "JavaScript thing" - but have more to do with computer graphics in general. Anything you see on a screen is made up of pixels.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Pixels are just the tiny squares that make up a digital image.`}</p>
    </blockquote>
    <p>{`When talking about computer graphics, we generally use pixels as our "unit of measurement". That is, we talk about the size of a picture in terms of pixels - not in terms of inches or centimeters.`}</p>
    <p>{`When producing graphics throughout this book, we'll always be working in pixels. Our default stage size is 200 pixels wide by 200 pixels tall. When we draw a shape, we'll specify its size and position in terms of pixels. Let's see an example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=wij-pixels",
        "live": "true",
        "id": "wij-pixels"
      }}>{`// Draw a rectangle that is:
// - 100 pixels wide
// - 50 pixels tall
$rect(100, 50);
`}</code></pre>
    <p>{`This previous code snippet creates a rectangle that is 100 pixels wide and 50 pixels tall. The size of the stage is shown at the bottom-right corner of the stage. Notice that if you resize the stage, that value will change!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      